/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import authBG from '../assets/img/auth/auth_bg.png'
// core components
import Login from "../components/pages/Login";


const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div

          className="main-content" ref={mainContent}>
        <div
          style={{
            height: "100vh",
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            background: "#1d1d1d",
            backgroundImage: `url("${authBG}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
          className="py-7 py-lg-8">
          <Container>
            <Row className="justify-content-center">
              <Switch>
                {/*{getRoutes(routes)}*/}
                <Route path={"*"} component={Login} />
                {/*<Route from="*" to="/login" />*/}
              </Switch>
            </Row>
          </Container>
        </div>
        {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        {/* Page content */}

      </div>

    </>
  );
};

export default Auth;
