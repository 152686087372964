export default class Palette {
    static KOMUNE_YELLOW = "#febe10";
    static MAIN_THEME = "#ef6024";
    static CATALYST_PINK = "#ef6024";

    static BARCODE_ORANGE = "#ef6024";
    static BARCODE_GRAY = "#E0E0E0";
    static BARCODE_BLUE = "#004EEB";
    static BACKGROUND_BLACK = "#121212";
    static BACKGROUND_DARK_GRAY = "#1d1d1d";
    static INACTIVE_GRAY = "#c2c2c2";

    static THEME_YELLOW = "#febe10";
    static THEME_ORANGE = "#fb6340";
    static THEME_PURPLE = "#7f8fea";
    static THEME_GREEN = "#43936C";
    static THEME_RED = "#B53235";

    static LIGHT_GRAY = "#404040";
    static WHITE_GRAY = "#757575";
}
