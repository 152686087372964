const Constant =  {
    BOTTOM_BAR_LAYOUT_PADDING: 20,
    ADVANCED_CLASS_MINIMUM_TIME: 43,
    PRO_CLASS_MINIMUM_TIME: 28,
    MAXIMUM_DRIVER_PER_SESSION: 10,
    OPERATIONAL_HOURS_START: {
        hour: 10,
        minute: 0,
        second: 0,
        fullTimeString: '10:00',
    },
    OPERATIONAL_HOURS_END: {
        hour: 22,
        minute: 0,
        second: 0,
        fullTimeString: '22:00',
    },
    TIME_PER_SESSION: 9,
};

export default Constant;
